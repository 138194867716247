/**
 * Home component
 */
.Home {
  padding: 20px 0;

  .card {
    margin-bottom: 20px;
  }
}
